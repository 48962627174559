.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}

.exercises {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.infos {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info_value {
  font-size: xx-large;
  font-weight: bold;
}

.info_label {
  color: var(--text-secondary-color);
}
