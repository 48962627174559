.root {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  max-width: min(100%, 700px);
  gap: 1rem;
  align-items: center;
  height: 100%;
}
