.root {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  max-width: min(100%, 700px);
  gap: 1rem;
  align-items: center;
  height: 100%;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 3rem 1rem 6rem 1rem;
  box-sizing: border-box;
}

.get_app {
  max-width: 80%;
  background: var(--tint-color);
  text-align: center;
  color: white;
  font-weight: bolder;
  font-size: large;
  padding: 1rem;
  text-decoration: none;
  border-radius: 5px;
  flex-shrink: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: absolute;
  bottom: 1rem;
}

.get_app > img {
  width: 20px;
}

.measurement_toggle {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
