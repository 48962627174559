.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}

.exercises {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
