.root {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  overflow: hidden;
  flex-grow: 1;
}
