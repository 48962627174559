.root {
  width: 54px;
}

.title {
  display: block;
  font-size: smaller;
  text-align: center;
}

.image {
  width: 54px;
  height: 54px;
  object-fit: contain;
  border-radius: 5px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: var(--text-secondary-color);
}

.root[mode='small'] {
  width: 48px;
}

.root[mode='small'] > .title {
  width: 48px;
  font-size: x-small;
}

.root[mode='small'] > .image {
  width: 48px;
  height: 48px;
}
