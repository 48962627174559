.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}

.title {
  padding: 1rem;
  border-style: solid;
  border-color: var(--tint-color);
  border-radius: 5px;
  border-width: 1px;
}

.days {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
