.root {
  display: flex;
  flex-direction: row;
  border-radius: 999px;
  background-color: lightgrey;
}

.root button {
  border: none;
  background: none;
  padding: 0.2rem 0.5rem;
  font-weight: bold;
}

.root button[current] {
  border-radius: 999px;
  background-color: var(--tint-color);
  color: white;
}
