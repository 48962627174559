:root {
  --background-color: #050117;
  --tint-color: #2478ff;
  --text-color: white;
  --text-secondary-color: #959caf;
  --card-color: #1e1e34;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ellipsis-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.font-size-smaller {
  font-size: smaller;
}

.font-size-xx-large {
  font-size: xx-large;
}

.font-size-x-large {
  font-size: x-large;
}

.font-size-larger {
  font-size: larger;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-semibold {
  font-weight: 600;
}

.text-align-center {
  text-align: center;
}

.color-text-secondary {
  color: var(--text-secondary-color);
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
