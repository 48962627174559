.root {
  position: relative;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.clone_span {
  position: absolute;
}
