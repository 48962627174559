.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  padding: 3rem;
}

.app_logo {
  max-width: 200px;
}

.description {
  text-align: center;
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
}

.links > div {
  width: calc(50% - 0.5rem);
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links > div > a {
  width: 100%;
}

.links > div > a > img {
  width: 100%;
}

.legal_attribution {
  display: flex;
  flex-direction: column;
  font-size: x-small;
  text-align: center;
  color: var(--text-secondary-color);
}
