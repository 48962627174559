.root {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--card-color);
  padding: 1rem;
  border-radius: 5px;
  gap: 1rem;
}

.exercises {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow-x: auto;
}
